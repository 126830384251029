import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/7B/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/7B/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/7B/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/7B/4.jpg';
import p5 from 'assests/Photos/ClassAssembly/2023/7B/5.jpg';
import p6 from 'assests/Photos/ClassAssembly/2023/7B/6.jpg';
import p7 from 'assests/Photos/ClassAssembly/2023/7B/7.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly7B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 2,
    },
    {
        src: p7,
        source: p7,
        rows: 1.2,
        cols: 2,
    },
    {
        src: p1,
        source: p1,
        rows: 1,
        cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
    },
    {
        src: p2,
        source:p2,
        rows: 1,
        cols: 2,
    },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 2,
    },
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 2,
    },  
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Namma Karunadu  
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 7B   Date: 20 NOVEMBER 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        To emphasize the vibrant tapestry of Karnataka’s rich cultural  and diverse heritage, class 7B hosted a spectacular assembly on the topic-“ Namma Karunadu”.The assembly showcased the fascinating rise of the Hoysala kingdom through a mesmerising dance drama. Intricate hand formations by the students depicted significance of renowned historical places in Karnataka.
        <br></br>
        The assembly also featured the exuberant and energetic Kolkata dance. Students, adorned in colourful attire, created a lively atmosphere as they moved in rhythmic patterns. Quiz on the topic highlighted key aspects of the state and fostered a sense of pride and deeper connection to the state’s unique identity.
        </Typography>
        </Box>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “The beauty of Karnataka lies in its diversity and unique cultural blend that it offers”
        <br/>
        </Typography>
        <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 350 : 300}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly7B2023;